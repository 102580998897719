<template>
  <div>
    <HeadCrudApp :app="app" />
    <hr style="width: 70%" class="mt-5 mb-0" />
    <BodyCrudApp :app="app" />
  </div>
</template>

<style scoped lang="sass">
@import "~/public/assets/styles/scss/pages/users.scss"
</style>

<script>
import AppService from "../../services/AppService/AppService";

import HeadCrudApp from "./components/HeadCrudApp.vue";
import BodyCrudApp from "./components/BodyCrudApp.vue";

export default {
  name: "CrudApps",
  components: { HeadCrudApp, BodyCrudApp },

  data() {
    return {
      appService: null,
      app: null,
    };
  },

  mounted() {
    this.appService = new AppService();
    this.getById();
  },

  methods: {
    async getById() {
      let id = this.$route.params.id;
      this.app = await this.appService.getAppId(id);
    },
  },
};
</script>
