<template>
  <div class="container mt-3 mb-3">
    <div class="row" style="justify-content: center">
      <div class="col-5">
        <label>Nome do aplicativo</label>
        <b-form-input
          v-model="app.name"
          placeholder="Digite o nome do app"
        ></b-form-input>
      </div>
      <div class="col-5">
        <label>Descrição do aplicativo</label>
        <b-form-input
          v-model="app.desc"
          placeholder="Digite o nome do app"
        ></b-form-input>
      </div>
    </div>

    <div class="row mt-3" style="justify-content: center">
      <div class="col-10">
        <label>URL</label>
        <b-form-input
          v-model="app.url"
          placeholder="Digite o nome do app"
        ></b-form-input>
      </div>
    </div>

    <div class="row mt-3" style="justify-content: center">
      <div class="col-md-4">
        <label for="inputState">Selecione a logo</label>
        <input
          class="form-control"
          @change="onFileChange"
          type="file"
          id="formFile"
        />
      </div>
      <!-- input de Área -->
      <div class="col-md-3 mb-3">
        <label for="inputState">Área</label>
        <select
          id="inputState"
          multiple
          v-model="app.areas"
          class="form-control"
        >
          <option :value="area.id" v-for="(area, i) in areas" :key="i">
            {{ area.name }}
          </option>
        </select>
      </div>

      <!-- input de Setor -->
      <div class="col-md-3 mb-3">
        <label for="inputState">Setor</label>
        <select
          multiple
          id="inputState"
          v-model="app.departments"
          class="form-control"
        >
          <option :value="setor.id" v-for="(setor, i) in departments" :key="i">
            {{ setor.name }}
          </option>
        </select>
      </div>
    </div>

    <div>
      <div class="col-md-4 mb-3">
        <b-form-group label="O aplicativo é:" v-slot="{ ariaDescribedby }">
          <b-form-radio-group
            class="mt-2"
            v-model="app.public"
            :options="options"
            :aria-describedby="ariaDescribedby"
            name="radio-inline"
          ></b-form-radio-group>
        </b-form-group>
      </div>
    </div>

    <div class="row mt-3" style="justify-content: flex-end">
      <b-button class="mr-2" @click="cancelar">Cancelar</b-button>
      <b-button variant="danger" class="px-4" @click="editApp(app)"
        >Salvar</b-button
      >
      <div class="col-1"></div>
    </div>
  </div>
</template>

<script>
import AreaService from "../../../services/AreaService/AreaService";
import DepartmentService from "../../../services/DepartmentService/DepartmentService";

import AppService from "../../../services/AppService/AppService";

export default {
  name: "BodyCrudApps",
  props: ["app"],

  data() {
    return {
      areas: [],
      areaService: null,
      departments: [],
      departmentService: null,
      department: null,
      appService: null,
      options: [
        { text: "Público", value: "true" },
        { text: "Privado", value: "false" },
      ],
    };
  },

  mounted() {
    this.appService = new AppService();
    this.areaService = new AreaService();
    this.getAllAreas();
    this.departmentService = new DepartmentService();
    this.getAllDepartments();
  },

  methods: {
    async getAllAreas() {
      this.areas = await this.areaService.allAreas();
    },

    async getAllDepartments() {
      this.departments = await this.departmentService.allDepartments();
    },

    async editApp(app) {
      await this.appService.getUpdateApp(app);
    },

    onFileChange(e) {
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.app.logo = files[0];
    },

    cancelar() {
      return this.$router.push("/library");
    },
  },
};
</script>
