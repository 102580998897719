<template>
  <div class="container mt-4 mb-3">
    <div
      class="row"
      :app="app"
      style="justify-content: center; align-items: center"
    >
      <div class="col-8">
        <h1 style="font-size: 30px; color: #a1000c; font-weight: bold">
          {{ app.name }}
        </h1>
        <p>{{ app.desc }}</p>
        <b-button
          variant="light"
          class="px-4 mr-2"
          @click="removeApp(app.id)"
          v-b-tooltip.hover.bottom="'Remover da minha lista de aplicativos'"
          >Remover</b-button
        >
        <b-button
          variant="danger"
          class="px-4"
          @click="adicionarApp(app.id)"
          v-b-tooltip.hover.right="'Adicionar a minha lista de aplicativos'"
          >Adicionar</b-button
        >
      </div>
      <div class="col-2">
        <img
          v-bind:src="app.logo_thumb"
          class="p-2"
          style="border-radius: 100px; border: 1px solid grey"
        />
        <div class="row justify-content-end">
          <b-button
            class="p-0"
            v-b-tooltip.hover.right="'Excluir aplicativo do sistema'"
            style="background-color: white; border: 0px"
          >
            <span
              class="material-icons"
              @click="deleteApp(app.id)"
              style="text-align: right; cursor: pointer; color: #a1000c"
            >
              delete
            </span>
          </b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AppService from "../../../services/AppService/AppService";
import UserService from "../../../services/UserService/UserService";

import Swal from "sweetalert2";

export default {
  name: "HeadCrudApps",
  props: ["app"],
  data() {
    return {
      appService: null,
    };
  },
  mounted() {
    this.appService = new AppService();
  },
  methods: {
    async adicionarApp(id) {
      let apps = await new AppService().userApps();
      let idApps = [];
      for (let app of apps) {
        idApps.push(app.id);
      }

      idApps.push(id);

      let userId = await new UserService().me();
      console.log(userId.id);
      await new UserService().editUser({ apps: idApps, id: userId.id });
      return this.$router.push("/library");
    },

    async removeApp(id) {
      let apps = await new AppService().userApps();
      let idApps = [];
      for (let app of apps) {
        idApps.push(app.id);
      }

      let index = idApps.indexOf(id);
      if (index !== -1) {
        idApps.splice(index, 1);
      }

      let userId = await new UserService().me();
      console.log(userId.id);
      await new UserService().editUser({ apps: idApps, id: userId.id });
      return this.$router.push("/library");
    },

    async deleteApp(id) {
      Swal.fire({
        title: "Tem certeza que quer excluir esse aplicativo do sistema?",
        text: "Essa ação não poderá ser desfeita",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#BF0C0E",
        cancelButtonColor: "#A9A9A9",
        confirmButtonText: "Deletar",
      }).then((result) => {
        if (result.isConfirmed) {
          this.appService.deleteApp(id);
          Swal.fire("Deleted!", "Aplicativo deletado com sucesso", "success");
          return this.$router.push("/library");
        }
      });
    },
  },
};
</script>
